import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { User } from 'oidc-client-ts';
import { AuthProvider } from 'react-oidc-context';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import './App.css';
import { useConfig } from './ConfigContext';
import Home from './Home';
import NotFound from './NotFound';
import Protected from './Protected';
import Root from './Root';
import ProcessorDetails from './ProcessorDetails';
import UnisvcDeployDetails from './UnisvcDeployDetails';


const routes = createRoutesFromElements(
  <Route path='/' element={<Root />}>
    <Route path="" element={<Home />} />
    <Route path="processor" element={<ProcessorDetails />} />
    <Route path="unisvc/deploy" element={<UnisvcDeployDetails />} />
    <Route path="*" element={<NotFound />} />
  </Route>
);
const router = createBrowserRouter(routes, {});
const queryClient = new QueryClient();

function App() {
  var config = useConfig().config;
  const onSigninCallback = (_user: User | void): void => {
    window.history.replaceState(
      {},
      document.title,
      window.location.pathname
    )
  }
  if (!config) {
    return <div>Loading...</div>;
  } else {
    const oidcConfig = {
      authority: config.oidcAuthority,
      client_id: config.oidcClientId,
      redirect_uri: config.oirdRedirectUri,
      onSigninCallback,
    };

    return <AuthProvider {...oidcConfig}>
      <QueryClientProvider client={queryClient}>
        <Protected>
          <RouterProvider router={router} />
        </Protected>
      </QueryClientProvider>
    </AuthProvider>
  }
}

export default App;
