import Grid from '@mui/material/Unstable_Grid2';

function NotFound() {
    return (
        <>
            <Grid>
                NotFound
            </Grid>
        </>
    )
}
export default NotFound;
