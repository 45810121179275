import { Button, Stack, TextField, Grid, } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { ReactNode, useState } from 'react';
import { useAuth } from "react-oidc-context";
import { useLocation } from "react-router-dom";

function UnisvcDeployDetails() {

    const auth = useAuth();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const id = params.get('id');
    const [env, type, group, name, version] = id!.split('/');

    const { isPending, error, data } = useQuery({
        queryKey: ['unisvc', 'deploy_info', env, type, group, name, version],
        queryFn: async () => {
            const res = await fetch(`/api/v1/unisvc/deploy_info/${env}/${type}/${group}/${name}/${version}`, {
                headers: {
                    'Authorization': 'Bearer ' + auth.user!.access_token,
                }
            });
            if (!res.ok) {
                const txt = await res.text();
                throw new Error(`Failed to get unsivc deploy info (${txt})`);
            }
            return await res.json();
        },
        retry: 3,
    });
    const { isPending: envIsPending, error: envError, data: envData } = useQuery({
        queryKey: [],
        queryFn: async () => {
            const res = await fetch(`/api/v1/envs`, {
                headers: {
                    'Authorization': 'Bearer ' + auth.user!.access_token,
                }
            })
            if (!res.ok) {
                const txt = await res.text();
                throw new Error(`Failed to get envs: (${txt})`);
            }
            return await res.json();
        },
        retry: 3,
    });

    const [selectedEnv, setSelectedEnv] = useState<string>('');
    const [selectedDeploymentRunId, setSelectedDeploymentRunId] = useState<string>('');

    let dataSection: ReactNode = <></>

    if (isPending) {
        return <div>Loading...</div>;
    } else if (error) {
        return <div>Error: {error.message}</div>;
    } else if (data == null) {
        return <>No data</>;
    }

    const { deploy_infos } = data;

    let deploySection = <></>
    let deployToNonDevEnv = <></>
    let envs: string[] = [];

    if (envIsPending || envError || envData == null) {
        envs = [];
    } else {
        envs = envData;
    }

    const table = {
        'Deployment Run ID':
        {
            'label': 'Deployment Run ID',
            'valueFn': (p: any) => p.deployment_run_id,
        },
        'Created At':
        {
            'label': 'Created At',
            'valueFn': (p: any) => p.create_time,
        },
        'Git Commit':
        {
            'label': 'Git Commit',
            'valueFn': (p: any) => p.git_commit_id.substring(0, 7),
        },
        'Code Hash':
        {
            'label': 'Code Hash',
            'valueFn': (p: any) => p.code_hash.substring(0, 7),
        },
        'Zhmle Package':
        {
            'label': 'Zhmle Package',
            'valueFn': (p: any) => p.zhmle_package,
        },
        'Running':
        {
            'label': 'Running',
            'valueFn': (p: any) => String(p.running),
        }
    }

    const handleRedeploy = (env: string, deployment_run_id: any) => {

        fetch(`/api/v1/unisvc/redeploy/${env}/${deployment_run_id}`,
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + auth.user!.access_token,
                }
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Redeploy failed');
                }
                return response.json();
            })
            .then(data => {
                alert(JSON.stringify(data));
            })
            .catch(error => {
                alert(`There was a problem with the redeploy operation: ${error.message}`);
                console.error('There was a problem with the redeploy operation:', error);
            });
    };

    const handlerDeploy = (env: string, deployment_id: any) => {
        fetch(`/api/v1/unisvc/deploy/${env}/${deployment_id}`,
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + auth.user!.access_token,
                }
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Deploy failed');
                }
                return response.json();
            })
            .then(data => {
                alert(JSON.stringify(data));
            })
            .catch(error => {
                alert(`There was a problem with the deploy operation: ${error.message}`);
                console.error('There was a problem with the deploy operation:', error);
            });
    };

    const handleDeployToNonDevEnv = () => {
        fetch(`/api/v1/unisvc/deploy_to/${selectedEnv}?dev_deployment_run_id=${selectedDeploymentRunId}`,
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + auth.user!.access_token,
                }
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`Deploy to non dev env failed, selectedEnv: ${selectedEnv}, selectedDeploymentRunId: ${selectedDeploymentRunId}`);
                }
                return response.json();
            })
            .then(data => {
                alert(JSON.stringify(data));
            })
            .catch(error => {
                alert(`There was a problem with the deploy to non-dev env operation: ${error.message}`);
                console.error('There was a problem with the deploy to non-dev env operation:', error);
            });
    };

    const makeRow = (p: any) => {
        const id = env + '/' + p.deployment_run_id;
        return <tr key={id}>
            {Object.keys(table).map((k) => {
                return <td key={k} style={{ textAlign: 'left' }}>{(table as any)[k].valueFn(p)}</td>
            })}
            <td >
                <button onClick={() => handleRedeploy(env, p.deployment_run_id)}>Redeploy</button>
            </td>
        </tr>
    }

    dataSection = (<table>
        <thead>
            <tr>
                {Object.keys(table).map((k) => {
                    return <th key={k} style={{ textAlign: 'left' }}>{(table as any)[k].label}</th>
                })}
                <th style={{ textAlign: 'left' }}>Action</th>
            </tr>
        </thead>
        <tbody>
            {deploy_infos.map(makeRow)}
        </tbody>
    </table>)


    const deploymentRunIdOptions = deploy_infos.map((deploy_info: any) => {
        return <option value={deploy_info.deployment_run_id}>{deploy_info.deployment_run_id}</option>
    }
    );

    const envsOptions = envs.filter((env: any) => !env.endsWith("dev")).map((env: any) => {
        return <option value={env}>{env}</option>
    }
    );


    if (env.toLowerCase().endsWith('dev')) {
        deploySection = (
            <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={() => handlerDeploy(env, deploy_infos[0]?.deployment_id)}>Create New Deployment Run</Button>
            </Grid>
        )
        deployToNonDevEnv = (
            <Grid container spacing={1} alignItems="center">
                <Grid item >
                    <TextField value={"Deploy dev workflow run"} disabled />
                </Grid>
                <Grid item >
                    <TextField
                        select
                        label="workflow run id"
                        variant="outlined"
                        SelectProps={{ native: true }}
                        value={selectedDeploymentRunId}
                        onChange={(e) => setSelectedDeploymentRunId(e.target.value)}
                        style={{ width: 120 }}
                        InputLabelProps={{
                            shrink: true, // 让 label 始终显示在左上角
                        }}
                    >
                        <option value="">None</option>
                        {deploymentRunIdOptions}
                    </TextField>
                </Grid>
                <Grid item>
                    <TextField value={"to env "} disabled />
                </Grid>

                <Grid item>
                    <TextField
                        select
                        label="env"
                        variant="outlined"
                        SelectProps={{ native: true }}
                        value={selectedEnv}
                        onChange={(e) => setSelectedEnv(e.target.value)}
                        style={{ width: 120 }}
                        InputLabelProps={{
                            shrink: true, // 让 label 始终显示在左上角
                        }}
                    >
                        <option value="">None</option>
                        {envsOptions}
                    </TextField>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={() => handleDeployToNonDevEnv()}>
                        Deploy to non-dev env
                    </Button>
                </Grid>
            </Grid>
        )
    }


    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12}> <TextField label="Env" value={env} variant="outlined" fullWidth disabled /></Grid>
                <Grid item xs={12}> <TextField label="Group" value={group} variant="outlined" fullWidth disabled /></Grid>
                <Grid item xs={12}> <TextField label="Name" value={name} variant="outlined" fullWidth disabled /></Grid>
                <Grid item xs={12}> <TextField label="Version" value={version} variant="outlined" fullWidth disabled /></Grid>
                <Grid item xs={12}> <TextField label="Deployment Id" value={deploy_infos[0]?.deployment_id} variant="outlined" fullWidth disabled /></Grid>
                <Grid item xs={12}> <TextField label="Git Repo" value={deploy_infos[0]?.git_repo} variant="outlined" fullWidth disabled /></Grid>
                <Grid item xs={12}> <TextField label="Git Revision" value={deploy_infos[0]?.git_revision} variant="outlined" fullWidth disabled /></Grid>
                {deploySection}
            </Grid>

            {deployToNonDevEnv}
            <Stack>
                {dataSection}
            </Stack>
        </div>
    );
}

export default UnisvcDeployDetails;