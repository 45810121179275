import { Box, Input, InputLabel, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ReactNode, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useLocation, useNavigate } from 'react-router-dom';

function TypeSelect(props: { labelId: string, id: string, label: string, onChange: (e: SelectChangeEvent) => void, value: string }) {
    return <Select
        {...props}
    >
        <MenuItem value={'service'}>Service</MenuItem>
        <MenuItem value={'workflow'}>Workflow</MenuItem>
        <MenuItem value={''}>All</MenuItem>
    </Select>
}

function EnvSelect(props: { labelId: string, id: string, label: string, onChange: (e: SelectChangeEvent) => void, value: string }) {

    const [options, setOptions] = useState<string[]>([]);

    useEffect(() => {
        // 使用 fetch 或其他方法从接口获取数据
        fetch("/api/v1/envs")
            .then(response => response.json())
            .then(data => setOptions(data))
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    return (
        <Select
            {...props}
        >
            <MenuItem value={''}>All</MenuItem>
            {options.map(option => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
        </Select>
    );
}

function Home() {
    const location = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(location.search);

    const env = params.get('env');
    const type = params.get('type');
    const group = params.get('group');
    const name = params.get('name');
    const version = params.get('version');
    const page = parseInt(params.get('page') || '0');
    const pageSize = parseInt(params.get('pageSize') || '20');
    const visibility = params.get('visibility');

    const auth = useAuth();
    let dataSection: ReactNode = <></>;
    let total = 0;

    // not need to mess with this
    const { isPending, error, data } = useQuery({
        queryKey: ['processors', 'list', env, type, group, name, version, visibility, page, pageSize],
        queryFn: async () => {
            const searchParams_: { [key: string]: string } = {
                page: '' + page,
                pageSize: '' + pageSize,
            };
            if (env) {
                searchParams_['env'] = env;
            }
            if (type) {
                searchParams_['type'] = type;
            }
            if (group) {
                searchParams_['group'] = group;
            }
            if (name) {
                searchParams_['name'] = name;
            }
            if (version) {
                searchParams_['version'] = version;
            }
            if (visibility) {
                searchParams_['visibility'] = visibility;
            }
            const searchParams = new URLSearchParams(searchParams_);
            const res = await fetch('/api/v1/processors?' + searchParams.toString(), {
                headers: {
                    'Authorization': 'Bearer ' + auth.user!.access_token,
                }
            });
            if (!res.ok) {
                const txt = await res.text();
                throw new Error(`Failed to fetch processor list (${txt})`);
            }
            return await res.json();
        },
        retry: 3,
    });
    if (isPending) {
        dataSection = <>Loading</>
    } else if (error) {
        dataSection = <>Failed to load, reason: {'' + error}</>
    } else {
        total = data.total;
        const processors = data.processors;
        // add new columns here
        const table = {
            'Env': {
                'label': 'Env',
                'valueFn': (p: any) => p.basic.env,
            },
            'Type': {
                'label': 'Type',
                'valueFn': (p: any) => p.basic.type,
            },
            'Group': {
                'label': 'Group',
                'valueFn': (p: any) => p.basic.group,
            },
            'Name': {
                'label': 'Name',
                'valueFn': (p: any) => p.basic.name,
            },
            'Version': {
                'label': 'Version',
                'valueFn': (p: any) => p.basic.version,
            },
            'Visiblity': {
                'label': 'Visiblity',
                'valueFn': (p: any) => p.basic.visibility,
            },
            // 'K8s Name': {
            //     'label': 'K8s Name',
            //     'valueFn': (p: any) => p.k8s?.name,
            // },
            'Deployment Id': {
                'label': 'Deployment Id',
                'valueFn': (p: any) => p.deploy_infos[0]?.deployment_id,
            },
            'Deployment Run Id': {
                'label': 'Deployment Run Id',
                'valueFn': (p: any) => {
                    for (let i = 0; i < p.deploy_infos.length; i++) {
                        if (p.deploy_infos[i]?.running) {
                            return p.deploy_infos[i]?.deployment_run_id;
                        }
                    }
                },
            },
            'K8s replicas': {
                'label': 'K8s replicas',
                'valueFn': (p: any) => p.k8s ? (p.k8s?.ready_replicas + "/" + p.k8s?.replicas) : null,
            }
        }
        const makeRow = (p: any) => {
            const id = p.env + '/' + p.basic.type + '/' + p.basic.group + '/' + p.basic.name + '/' + p.basic.version;
            let deploy_info = <></>
            if (p.deploy_infos.length > 0) {
                deploy_info = <a href={'/unisvc/deploy?id=' + id} style={{ textAlign: 'left' }}>View</a>
            }
            return <tr key={id}>
                {Object.keys(table).map((k) => {
                    return <td key={k} style={{ textAlign: 'left' }}>{(table as any)[k].valueFn(p)}</td>
                })}
                <td >
                    <a href={'/processor?id=' + id} style={{ textAlign: 'left' }}>View</a>
                </td>
                <td>
                    {deploy_info}
                </td>
            </tr>
        }

        dataSection = (<table>
            <thead>
                <tr>
                    {Object.keys(table).map((k) => {
                        return <th key={k} style={{ textAlign: 'left' }}>{(table as any)[k].label}</th>
                    })}
                    <th style={{ textAlign: 'left' }}>Basic Info</th>
                    <th style={{ textAlign: 'left' }}>Deploy Info</th>
                </tr>
            </thead>
            <tbody>
                {processors.map(makeRow)}
            </tbody>
        </table>)
    }

    const totalPages = Math.ceil(total / pageSize);
    let pageNumbersToShow: Set<number> = new Set();
    pageNumbersToShow.add(0);
    pageNumbersToShow.add(totalPages - 1);
    for (let i = page - 2; i <= page + 2; i++) {
        pageNumbersToShow.add(i);
    }
    let pageNumbers = Array.from(pageNumbersToShow).filter((v) => v >= 0 && v < totalPages);
    pageNumbers.sort((a, b) => a - b);
    function toPage(page: number) {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('page', '' + page);
        searchParams.set('pageSize', '' + pageSize);
        navigate(location.pathname + '?' + searchParams.toString());
    }
    let prevN = -1;
    let i = 0;
    let paginationItems: ReactNode[] = [];
    function _makePItem(txt: string, page?: number) {
        i += 1;
        if (page === undefined) {
            return <span key={i} style={{
                margin: '0.5em',
            }}>{txt}</span>;
        } else {
            return <a key={i} onClick={() => toPage(page)} href="#" style={
                {
                    margin: '0.5em',
                }
            }>{txt}</a>;
        }
    }
    pageNumbers.map((pn) => {
        if (pn - prevN > 1) {
            paginationItems.push(_makePItem("..."));
        }
        if (pn === page) {
            paginationItems.push(_makePItem('[' + (pn + 1) + ']'));
        }
        else {
            paginationItems.push(_makePItem('' + (pn + 1), pn));
        }
        prevN = pn;
    });

    function _makeControl<T>(id: string, value: string, label: string, type: T, field: string) {
        const Type: any = type;
        return <>
            <InputLabel id={id + "-label"}>{label}</InputLabel>
            <Type
                labelId={id + "-label"}
                id={id}
                value={value}
                onChange={(e: any) => {
                    const v = e.target.value;
                    const searchParams = new URLSearchParams(location.search);
                    if (v) {
                        searchParams.set(field, '' + v);
                    } else {
                        searchParams.delete(field);
                    }
                    navigate(location.pathname + '?' + searchParams.toString());
                }}
            />
        </>
    }

    return (
        <>
            <Stack>
                <Stack sx={{
                    marginBottom: 2,
                }}>
                    {_makeControl('select-env', env || '', 'Env', EnvSelect, 'env')}
                    {_makeControl('select-type', type || '', 'Type', TypeSelect, 'type')}
                    {_makeControl('input-group', group || '', 'Group', Input, 'group')}
                    {_makeControl('input-name', name || '', 'Name', Input, 'name')}
                    {_makeControl('input-version', version || '', 'Version', Input, 'version')}
                    {_makeControl('input-visiblity', visibility || '', 'Visibility', Input, 'visibility')}

                </Stack>
                {dataSection}
                <Box sx={{
                    marginTop: 1,
                    alignSelf: 'center',
                }}>
                    {paginationItems}
                </Box>
            </Stack>
        </>
    )
}
export default Home;
